var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "row justify-content-center align-content-center align-items-center vh-100 h-100 w-100 no-gutters bg-secondary",
    },
    [
      _vm.logInIframe === null || _vm.isLoading
        ? _c(
            "div",
            { staticClass: "col-10 col-sm-8 d-flex flex-column" },
            [
              _vm._m(0),
              _c("loading-square", { staticClass: "white-loading-square" }),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass:
                "col-10 col-sm-8 col-md-6 col-xl-4 mx-auto responsive-wrapper",
            },
            [
              _c("iframe", {
                staticClass: "w-100",
                attrs: {
                  sandbox: "allow-same-origin allow-scripts allow-forms",
                  src: _vm.logInIframe,
                },
              }),
            ]
          ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "img-container py-3 px-5 text-center mb-3" },
      [
        _c("img", {
          staticClass: "img-fluid mx-auto",
          attrs: {
            src: "https://f.hubspotusercontent30.net/hubfs/5009489/2994%20Logo%20Reviewbox%20COLOR+WHITE%20TEXT%20v01.png",
            alt: "Reviewbox Logo",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }