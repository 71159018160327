var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { id: "template-response-form" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmitForm.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        [
          _c("base-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.formValidations.name,
                expression: "formValidations.name",
              },
            ],
            attrs: {
              label: "Template name",
              placeholder: "Name of template",
              name: "name",
              error: _vm.errors.first("name"),
              disabled: _vm.isLoading,
              required: "",
            },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "mb-4" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column" },
          [
            _c("label", { attrs: { for: "template-type-input" } }, [
              _vm._v(" Type "),
            ]),
            _c(
              "el-select",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.formValidations.type,
                    expression: "formValidations.type",
                  },
                ],
                class: { invalid: _vm.errors.first("type") },
                attrs: {
                  id: "template-type-input",
                  disabled: _vm.isLoading,
                  name: "type",
                },
                model: {
                  value: _vm.form.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type",
                },
              },
              _vm._l(_vm.typeOptions, function (option, optionIndex) {
                return _c("el-option", {
                  key: optionIndex,
                  attrs: {
                    label: option.name,
                    value: option.value,
                    disabled: option.disabled,
                  },
                })
              }),
              1
            ),
            _vm.errors.first("type")
              ? _c("p", { staticClass: "text-danger mb-0" }, [
                  _c("small", [
                    _vm._v(" " + _vm._s(_vm.errors.first("type")) + " "),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _vm._m(0),
          _vm._l(_vm.form.customFields, function (field, index) {
            return _c("div", { key: index, staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-5" },
                [
                  _c("base-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.formValidations.addNewKey,
                        expression: "formValidations.addNewKey",
                      },
                    ],
                    attrs: {
                      "data-vv-scope": "filter" + index,
                      error: _vm.errors.first("filter" + index + ".addNewKey"),
                      label: "Field name",
                      name: "addNewKey",
                      placeholder: "Field Name",
                    },
                    on: {
                      input: (ev) => {
                        field.key = ev.toLowerCase()
                        _vm.$validator.validate(
                          "filter" + index + ".addNewValue"
                        )
                      },
                    },
                    model: {
                      value: field.key,
                      callback: function ($$v) {
                        _vm.$set(field, "key", $$v)
                      },
                      expression: "field.key",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column" },
                  [
                    _c(
                      "label",
                      { attrs: { for: `custom-field-value--${index}` } },
                      [_vm._v(" Field value ")]
                    ),
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.formValidations.addNewValue,
                            expression: "formValidations.addNewValue",
                          },
                        ],
                        class: {
                          "has-danger": !!_vm.errors.first(
                            `filter${index}.addNewValue`
                          ),
                        },
                        attrs: {
                          id: `custom-field-value--${index}`,
                          name: "addNewValue",
                          filterable: "",
                          "allow-create": "",
                          "default-first-option": "",
                          "data-vv-scope": "filter" + index,
                          placeholder: "Select or enter a value",
                        },
                        model: {
                          value: field.value,
                          callback: function ($$v) {
                            _vm.$set(field, "value", $$v)
                          },
                          expression: "field.value",
                        },
                      },
                      _vm._l(
                        _vm.customFieldOptions[field.key],
                        function (option) {
                          return _c("el-option", {
                            key: option,
                            attrs: { label: option, value: option },
                          })
                        }
                      ),
                      1
                    ),
                    _vm.errors.first("filter" + index + ".addNewValue")
                      ? _c("p", { staticClass: "mb-0 text-danger" }, [
                          _c("small", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.errors.first(
                                    "filter" + index + ".addNewValue"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "col-12 col-sm-3",
                  staticStyle: { "margin-top": "1.85rem" },
                },
                [
                  index + 1 === _vm.form.customFields.length
                    ? _c("el-button", {
                        attrs: {
                          type: "success",
                          icon: "el-icon-plus",
                          size: "mini",
                          circle: "",
                        },
                        on: { click: () => _vm.onAddCustomField(index) },
                      })
                    : _vm._e(),
                  index < _vm.form.customFields.length
                    ? _c("el-button", {
                        attrs: {
                          type: "danger",
                          icon: "el-icon-close",
                          size: "mini",
                          circle: "",
                        },
                        on: { click: () => _vm.onRemoveCustomField(index) },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          }),
        ],
        2
      ),
      _c(
        "div",
        [
          _c(
            "base-input",
            {
              attrs: {
                name: "response",
                error: _vm.errors.first("response"),
                disabled: _vm.isLoading,
              },
            },
            [
              _c("template", { slot: "label" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("span", { staticClass: "mr-1" }, [_vm._v("Response:")]),
                    _c(
                      "el-popover",
                      { attrs: { trigger: "hover", placement: "top" } },
                      [
                        _c("div", [
                          _c("h3", { staticClass: "popover-header" }, [
                            _vm._v(" Response Tokens "),
                          ]),
                          _c("div", { staticClass: "popover-body" }, [
                            _c("p", [
                              _vm._v(
                                " Use tokens in your response that will auto-populate with relevant information. "
                              ),
                            ]),
                            _c("p", [_vm._v(" Common tokens: ")]),
                            _c("ul", [
                              _c("li", [
                                _c("code", [_vm._v("<author>")]),
                                _c(
                                  "small",
                                  [
                                    _c("arrow-right-icon", {
                                      staticClass: "text-muted px-2",
                                    }),
                                  ],
                                  1
                                ),
                                _c("code", [_vm._v("John")]),
                              ]),
                              _c("li", [
                                _c("code", [_vm._v("<name>")]),
                                _c(
                                  "small",
                                  [
                                    _c("arrow-right-icon", {
                                      staticClass: "text-muted px-2",
                                    }),
                                  ],
                                  1
                                ),
                                _c("code", [_vm._v("Smith")]),
                              ]),
                              _c("li", [
                                _c("code", [_vm._v("<product>")]),
                                _c(
                                  "small",
                                  [
                                    _c("arrow-right-icon", {
                                      staticClass: "text-muted px-2",
                                    }),
                                  ],
                                  1
                                ),
                                _c("code", [_vm._v("Red Shirt")]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "text-default",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [_c("info-icon")],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.response,
                    expression: "form.response",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: _vm.formValidations.response,
                    expression: "formValidations.response",
                  },
                ],
                staticClass: "w-100",
                attrs: {
                  autocomplete: "off",
                  name: "response",
                  placeholder: _vm.textareaPlaceholder,
                  error: _vm.errors.first("response"),
                  rows: "6",
                  disabled: _vm.isLoading,
                },
                domProps: { value: _vm.form.response },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "response", $event.target.value)
                  },
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { "word-break": "break-word" } }, [
        _vm.formErrorMessage
          ? _c("small", { staticClass: "text-danger" }, [
              _vm._v(" * " + _vm._s(_vm.formErrorMessage) + " "),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "el-dialog__footer" },
        [
          _c(
            "el-button",
            {
              class: { disabled: _vm.isLoading },
              attrs: { type: "danger", disabled: _vm.isLoading, plain: "" },
              on: { click: _vm.onCancelTemplate },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                "native-type": "submit",
                loading: _vm.isLoading,
              },
            },
            [_vm._v(" Save template ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("label", [_vm._v("Custom fields")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }