var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "response-templates-container",
      staticStyle: { "max-width": "1200px" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("div", [
            _c("h4", [
              _vm._v(" Response Management Templates "),
              _c(
                "sup",
                [
                  _vm.templates && _vm.templates.length
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "Click to get a walkthrough of this page",
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "rb-help-btn btn btn-link btn-sm",
                              on: { click: _vm.onResponseTemplateWalkthrough },
                            },
                            [
                              _c("info-icon", {
                                staticClass: "rb-icon",
                                attrs: { title: "" },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("p", { staticClass: "text-muted" }, [
              _vm._v(
                " Templates provide a quick way to respond to a customer's review or question. "
              ),
            ]),
          ]),
          _c("response-templates-help-button", { staticClass: "ml-auto" }),
        ],
        1
      ),
      _vm.isLoading
        ? [_vm._m(0), _c("div", { staticClass: "skeleton skeleton-block" })]
        : _vm._e(),
      !_vm.isLoading
        ? [
            _vm.templates && _vm.templates.length
              ? _c("div", [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "div",
                      { staticClass: "rb-response-templates-filters" },
                      [
                        _c(
                          "el-form",
                          { attrs: { inline: true, "label-position": "top" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Template type" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "el-select--light",
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      size: "small",
                                    },
                                    model: {
                                      value: _vm.templateType,
                                      callback: function ($$v) {
                                        _vm.templateType = $$v
                                      },
                                      expression: "templateType",
                                    },
                                  },
                                  _vm._l(_vm.typeFilters, function (option) {
                                    return _c("el-option", {
                                      key: option.name,
                                      attrs: {
                                        value: option.value,
                                        label: option.name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Template name" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "Search template name",
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ml-auto rb-response-templates-create-button",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onCreateTemplate },
                          },
                          [_vm._v(" Create template ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "card rb-response-templates-table",
                      staticStyle: { overflow: "hidden" },
                    },
                    [
                      _c(
                        "el-table",
                        { attrs: { data: _vm.filteredTemplates } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "150",
                              prop: "name",
                              label: "Name",
                              sortable: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "type",
                              label: "Type",
                              sortable: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "250",
                              prop: "response",
                              label: "Template Response",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.response.slice(
                                                  0,
                                                  75
                                                ) + "..."
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3231806559
                            ),
                          }),
                          _vm._l(_vm.customFields, function (field, index) {
                            return _c(
                              "el-table-column",
                              {
                                key: index,
                                attrs: {
                                  "min-width": "100",
                                  prop: field,
                                  label: field,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return scope.row.customFields
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.customFields
                                                      .filter(
                                                        (f) => f.key === field
                                                      )
                                                      .reduce(function (
                                                        prevVal,
                                                        currVal,
                                                        idx
                                                      ) {
                                                        return idx == 0
                                                          ? currVal.value
                                                          : prevVal +
                                                              ", " +
                                                              currVal.value
                                                      },
                                                      "")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          : undefined
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    { staticClass: "text-capitalize" },
                                    [_vm._v(" " + _vm._s(field) + " ")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "175",
                              "header-align": "right",
                              label: "Actions",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rb-response-templates-table-actions text-right",
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: `Edit ${scope.row.name}`,
                                                "open-delay": 300,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "info",
                                                    size: "small",
                                                    plain: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onEditTemplate(
                                                        { ...scope.row }
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Edit ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: `Delete ${scope.row.name}`,
                                                "open-delay": 300,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "danger",
                                                    size: "small",
                                                    plain: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onDeleteTemplate(
                                                        { ...scope.row }
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Delete ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4036538176
                            ),
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ])
              : _c(
                  "div",
                  [
                    _c(
                      "empty-state",
                      {
                        attrs: {
                          title: "No Response Templates Exist",
                          subtitle: "Let's get started!",
                          image: _vm.emptyImage,
                        },
                      },
                      [
                        _c("div", { staticClass: "mb-4" }, [
                          _c("p", { staticClass: "card-text mb-1" }, [
                            _vm._v(
                              " Create a response template in four easy steps: "
                            ),
                          ]),
                          _c("ol", { staticClass: "mb-0" }, [
                            _c("li", [_vm._v("Give your template a name")]),
                            _c("li", [_vm._v("Select the response type")]),
                            _c("li", [_vm._v("Enter your template text")]),
                            _c("li", [_vm._v("Save your template")]),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            attrs: { slot: "card-actions" },
                            slot: "card-actions",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success" },
                                on: { click: _vm.onCreateTemplate },
                              },
                              [_vm._v(" Create template ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "65px",
            title: _vm.showModal.editValues
              ? "Edit Response Template"
              : "New Response Template",
            visible: _vm.showModal.show,
            "destroy-on-close": "",
            width: "75%",
            "custom-class": _vm.showModal.editValues
              ? "template-dialog dialog-secondary"
              : "template-dialog dialog-primary",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showModal, "show", $event)
            },
          },
        },
        [
          _c("template-modal", {
            attrs: {
              show: _vm.showModal.show,
              "edit-values": _vm.showModal.editValues,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.showModal, "show", $event)
              },
              "update:editValues": function ($event) {
                return _vm.$set(_vm.showModal, "editValues", $event)
              },
              "update:edit-values": function ($event) {
                return _vm.$set(_vm.showModal, "editValues", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("div", { staticClass: "d-flex mb-2" }, [
        _c("div", { staticClass: "skeleton skeleton-button mr-2" }),
        _c("div", { staticClass: "skeleton skeleton-button" }),
      ]),
      _c("div", [_c("div", { staticClass: "skeleton skeleton-button" })]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }