var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
      _c("h4", { staticClass: "card-title mb-2" }, [
        _vm._v(" Export Settings for " + _vm._s(_vm.selection.title) + " "),
      ]),
      _c("h6", { staticClass: "text-muted mb-0" }, [
        _vm._v(" Drag and drop fields to the export columns group "),
      ]),
    ]),
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c("h6", { staticClass: "text-muted" }, [_vm._v(" Fields ")]),
            _c(
              "draggable",
              {
                staticClass:
                  "list-group list-group-flush column-section rounded-0",
                attrs: { list: _vm.unusedList, group: "settings" },
              },
              _vm._l(_vm.unusedList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.type,
                    staticClass: "list-group-item dragitem rounded-0",
                  },
                  [_vm._v(" " + _vm._s(item.type) + " ")]
                )
              }),
              0
            ),
            _vm._m(0),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "col-md-1 d-flex flex-md-column justify-content-center align-items-center my-3",
          },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: "Add all columns",
                  "open-delay": 500,
                  placement: "top",
                },
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-secondary btn-sm mr-1 mr-md-0 mb-md-1",
                    on: { click: _vm.onAddAllColumns },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "d-none d-md-flex align-items-center" },
                      [_c("chevron-double-right-icon")],
                      1
                    ),
                    _c(
                      "span",
                      { staticClass: "d-xs-flex d-md-none align-items-center" },
                      [_c("chevron-double-down-icon")],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: "Remove all columns",
                  "open-delay": 500,
                  placement: "bottom",
                },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-sm",
                    on: { click: _vm.onRemoveAllColumns },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "d-none d-md-flex align-items-center" },
                      [_c("chevron-double-left-icon")],
                      1
                    ),
                    _c(
                      "span",
                      { staticClass: "d-xs-flex d-md-none align-items-center" },
                      [_c("chevron-double-up-icon")],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-7 used-list" },
          [
            _c("h6", { staticClass: "text-muted" }, [
              _vm._v(" Export columns "),
            ]),
            _c(
              "draggable",
              {
                staticClass:
                  "list-group list-group-flush column-section rounded-0",
                attrs: {
                  list: _vm.outputList,
                  group: "settings",
                  handle: ".drag-handle",
                },
              },
              _vm._l(_vm.outputList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.type,
                    staticClass: "list-group-item dragitem rounded-0",
                  },
                  [
                    _c("ColumnRow", {
                      attrs: { "initial-setting": item },
                      on: {
                        "update:name": function ($event) {
                          return _vm.updateTitle(index, $event)
                        },
                        "emit:remove": function ($event) {
                          return _vm.removeItem(index)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._m(1),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "card-footer" }, [
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _c(
            "el-button",
            { attrs: { plain: "" }, on: { click: _vm.emitCancel } },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                disabled: _vm.isValidInput,
                content:
                  "No empty entries allowed. Please add a column to the export settings.",
                "open-delay": 500,
              },
            },
            [
              _c(
                "el-button",
                {
                  class: { "is-disabled disabled": !_vm.isValidInput },
                  attrs: { type: "primary" },
                  on: { click: _vm.emitSubmit },
                },
                [_vm._v(" Save export preferences ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-2" }, [
      _c("small", { staticClass: "form-text text-muted" }, [
        _vm._v(" Tip: Click and drag the field to the export column group. "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-2" }, [
      _c("small", { staticClass: "form-text text-muted" }, [
        _vm._v(
          " Tip: Click and hold the drag icon on the left to re-arrange the export column order up-and-down. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }