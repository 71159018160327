var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-wrap" }, [
    _c("div", [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" " + _vm._s(_vm.brand) + " "),
      ]),
      _c("p", { staticClass: "mb-0" }, [
        _c("span", { staticClass: "font-weight-bold" }, [_vm._v("Source")]),
        _vm._v(": " + _vm._s(_vm.account.source) + " "),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "ml-auto" },
      [
        _c(
          "el-tooltip",
          {
            attrs: {
              content: `Disconnect ${_vm.brand} from your account`,
              "open-delay": 500,
            },
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "danger", size: "small", plain: "" },
                on: { click: _vm.onPressDelete },
              },
              [_vm._v(" Disconnect ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }