import { render, staticRenderFns } from "./UpdateSettings.vue?vue&type=template&id=00218a1a&scoped=true&"
import script from "./UpdateSettings.vue?vue&type=script&lang=js&"
export * from "./UpdateSettings.vue?vue&type=script&lang=js&"
import style0 from "./UpdateSettings.vue?vue&type=style&index=0&id=00218a1a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00218a1a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/service/packages/reviewbox-web-app/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00218a1a')) {
      api.createRecord('00218a1a', component.options)
    } else {
      api.reload('00218a1a', component.options)
    }
    module.hot.accept("./UpdateSettings.vue?vue&type=template&id=00218a1a&scoped=true&", function () {
      api.rerender('00218a1a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Account/ExportSettings/Components/UpdateSettings.vue"
export default component.exports