import BaseHttp from './BaseHttp';

class ExportSettingsHttp extends BaseHttp {
  /**
   *
   * Fetch user's export settings
   *
   */

  constructor() {
    super();
    this.existingSettings = [];
    this.defaultFields = [];
    this.defaultSettings = [];
    this.settings = [];
  }

  async getAllSettings(exportType) {
    /**
     * @param {String} exportType
     * gets all settings from exports and fields
     */

    // Wait for all promises to resolve before populating data
    return Promise.all([
      this.getExportSettings(exportType),
      this.getFieldSettings(exportType),
    ])
      .then(() => this.populateHeaders(exportType));
  }

  async getExportSettings(exportType) {
    // Get export settings
    const params = {
      export_type: exportType,
    };

    return this.get(`${this.PrependRoutes.reviewbox}/exportsettings`, params)
      .then((response) => {
        const {
          default_settings: defaultSettings,
          settings,
        } = response.data;

        // Gets default settings and existing settings (if any)
        this.defaultSettings = defaultSettings;
        this.existingSettings = settings;
      });
  }

  async getFieldSettings(exportType) {
    /**
     * @todo Workaround - Searchbox requires an extra options component, change API return values
     *
     * Gets custom field settings
     */

    const options = {};
    if (exportType === 'searchbox') {
      // special retrieval case for searchbox requests
      // defaults to 'catalog' otherwise on API
      options.service = 'terms';
    }

    return this.get(`${this.PrependRoutes.reviewbox}/fields`, options)
      .then((response) => {
        const { fields } = response.data;
        this.defaultFields = fields;
      });
  }

  populateHeaders(exportType) {
    /**
     * @todo Currently settings are in type, title pairs
     *
     * Reassign type, title pair as object
     * Populates settings to display
     */

    const cleanSettings = {
      defaultSettings: [],
      existingSettings: [],
      unusedSettings: [],
    };

    if (this.existingSettings !== null) {
      // If settings exist, set existingSettings
      // 'null' value comes from API, change to empty array
      cleanSettings.existingSettings = this.existingSettings.map((setting) => ({
        // type = first, title = second
        title: setting[1],
        type: setting[0],
      }));
    }

    // Set the default settings
    // Defaults have the same title and type
    cleanSettings.defaultSettings = this.defaultSettings.map((setting) => ({
      title: setting,
      type: setting,
    }));

    // Add custom fields
    // NOTE: Sellers have no custom fields
    if (exportType !== 'seller_summary') {
      Object.keys(this.defaultFields).forEach((field) => {
        // adds '_custom' heading to array
        const type = field.concat('_custom');

        // Pushes to cleanSettings
        cleanSettings.defaultSettings.push({
          title: type,
          type,
        });
      });
    }

    const containsObject = (obj, list) => {
      // Util used to find if object is in array
      // specific to this function because it specifies comparison key
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].type === obj.type) {
          return true;
        }
      }

      return false;
    };

    // Set list of unused items (e.g. items not in existingSettings)
    if (this.existingSettings !== null) {
      // Set unused items
      cleanSettings.defaultSettings.forEach((defaultItem) => {
        if (!(containsObject(defaultItem, cleanSettings.existingSettings))) {
          // include in unused items if the default is not in existing settings
          cleanSettings.unusedSettings.push(defaultItem);
        }
      });
    } else {
      // if existingSettings is null, then use all items from defaultSettings
      cleanSettings.unusedSettings = cleanSettings.defaultSettings;
    }
    // Returns an object containing arrays
    return cleanSettings;
  }

  postSettings(exportType, exportSettings) {
    // Reassign type, title object to array
    const payload = {
      export_type: exportType,
    };

    // Use a temporary Array to store exportSettings
    // will need to stringify JSON object
    const tempArray = [];

    exportSettings.forEach((setting) => tempArray.push([setting.type, setting.title]));

    // Set export settings to JSON string
    payload.export_settings = JSON.stringify(tempArray);

    return this.post(`${this.PrependRoutes.reviewbox}/exportsettings`, payload);
  }
}

export default new ExportSettingsHttp();
