var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-xl" }, [
    _c(
      "form",
      {
        staticClass: "row justify-content-center",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onSubmitContentGuidelines.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "col-12 col-md-10" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              {
                staticClass:
                  "card-header mb-0 pb-3 border-bottom d-flex justify-content-between align-items-center",
              },
              [
                _c("h5", { staticClass: "card-title mb-0" }, [
                  _vm._v(" Content Guidelines "),
                ]),
                _c(
                  "el-button",
                  {
                    class: { disabled: _vm.isLoading },
                    attrs: {
                      type: "info",
                      size: "small",
                      plain: "",
                      disabled: _vm.isLoading,
                    },
                    on: { click: _vm.onLoadRecommendedValues },
                  },
                  [_vm._v(" Load Recommended ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "col-12 h-100" },
                      [
                        _vm._l([1, 2, 3, 4, 5], function (item) {
                          return [
                            _c("div", { key: `skeleton-input--${item}` }, [
                              _c("div", {
                                staticClass: "skeleton skeleton-text mb-1 w-25",
                              }),
                              _c("div", {
                                staticClass: "skeleton skeleton-input mb-3",
                              }),
                            ]),
                          ]
                        }),
                      ],
                      2
                    )
                  : [
                      _vm._l(_vm.formInputs, function (input, index) {
                        return _c("base-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: input.validation,
                              expression: "input.validation",
                            },
                          ],
                          key: index,
                          attrs: {
                            label: input.title,
                            name: input.name,
                            placeholder: input.placeholder,
                            error: _vm.getError(input.name),
                          },
                          model: {
                            value: input.value.min,
                            callback: function ($$v) {
                              _vm.$set(input.value, "min", _vm._n($$v))
                            },
                            expression: "input.value.min",
                          },
                        })
                      }),
                      _vm.formError
                        ? _c("el-alert", {
                            staticClass: "mb-3",
                            attrs: {
                              type: "error",
                              closable: false,
                              "show-icon": "",
                              title: "Invalid Inputs",
                              description: _vm.formError,
                            },
                          })
                        : _vm._e(),
                    ],
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass:
                  "card-footer d-flex align-items-center justify-content-between",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.isSuccess ? "info" : "default",
                      loading: _vm.isUpdating,
                      plain: "",
                    },
                    on: { click: () => _vm.$router.push(_vm.cancelRoute) },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isSuccess ? "Return to Catalog" : "Cancel") +
                        " "
                    ),
                  ]
                ),
                _c(
                  "el-button",
                  {
                    class: { disabled: _vm.isLoading },
                    attrs: {
                      "native-type": "submit",
                      type: "success",
                      loading: _vm.isUpdating,
                      disabled: _vm.isLoading,
                    },
                  },
                  [_vm._v(" Update Guidelines ")]
                ),
              ],
              1
            ),
          ]),
          _vm._m(0),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mt-3" }, [
      _vm._v(" Need more help? "),
      _c(
        "a",
        {
          attrs: {
            href: "https://helpcenter.getreviewbox.com/getting-started-with-copybox#generic_copybox_guidelines",
            target: "_blank",
          },
        },
        [_vm._v(" Read about updating your Copybox guidelines. ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }