var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-12 col-xl-8",
        staticStyle: { "max-width": "1200px" },
      },
      [
        _c("div", { staticClass: "card mb-3" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.linkAccount("US")
                    },
                  },
                },
                [_vm._v(" Sync NA Account ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.linkAccount("EU")
                    },
                  },
                },
                [_vm._v(" Sync EU Account ")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "card" },
          [
            _vm._m(1),
            _vm.isLoading
              ? _c("div", { staticClass: "list-group list-group-flush" }, [
                  _vm._m(2),
                ])
              : [
                  _vm.adAccounts && _vm.adAccounts.length
                    ? _c(
                        "div",
                        { staticClass: "list-group list-group-flush" },
                        _vm._l(_vm.adAccounts, function (account) {
                          return _c("account-card", {
                            key: account.profileId,
                            staticClass:
                              "list-group-item list-group-item-action",
                            attrs: {
                              brand: account.brandName,
                              account: account,
                            },
                            on: {
                              "emit:delete": function ($event) {
                                return _vm.unlinkAccount(account)
                              },
                            },
                          })
                        }),
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("el-alert", {
                            attrs: {
                              type: "info",
                              title:
                                "No Ad Accounts found. Please connect an account from above.",
                              closable: false,
                              "show-icon": "",
                            },
                          }),
                        ],
                        1
                      ),
                ],
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header border-bottom" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v(" Sync Ad Accounts ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
      _c("h4", { staticClass: "card-title mb-0" }, [
        _vm._v(" Connected Ad Accounts "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-group-item" }, [
      _c("p", { staticClass: "skeleton skeleton-text" }),
      _c("p", { staticClass: "skeleton skeleton-text" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }