var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _vm.isSelect ? _c("div", { staticClass: "col-12" }, [_vm._m(0)]) : _vm._e(),
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "col-12", staticStyle: { "max-width": "1200px" } },
          [_vm._m(1)]
        )
      : _vm._e(),
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "col-12", staticStyle: { "max-width": "1200px" } },
          [
            _c(
              "div",
              { staticClass: "card", staticStyle: { overflow: "hidden" } },
              [
                _vm.isSelect
                  ? _c("export-type", {
                      on: { "update:selection": _vm.updateSelection },
                    })
                  : _vm._e(),
                !_vm.isSelect
                  ? _c("update-settings", {
                      attrs: {
                        "initial-unused": _vm.settings.unusedSettings,
                        "existing-list": _vm.settings.existingSettings,
                        selection: _vm.selection,
                      },
                      on: {
                        "emit:settings": _vm.onUpdateSettings,
                        "emit:cancel": _vm.onReset,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h4", [_vm._v(" Export Type ")]),
      _c("p", { staticClass: "text-muted" }, [
        _vm._v(" Click the type of export to edit the settings "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card mb-3" }, [
      _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
        _c("h4", { staticClass: "skeleton skeleton-title card-title mb-0" }),
      ]),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "skeleton skeleton-block--large" }),
          ]),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "skeleton skeleton-block--large" }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }