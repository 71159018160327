var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex" }, [
    _c(
      "div",
      {
        staticClass: "drag-handle cursor-grab mr-3",
        staticStyle: { "margin-top": "1.75rem" },
      },
      [_c("drag-icon", { staticClass: "rb-icon-sm", attrs: { title: "" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "flex-grow-1" },
      [
        _c(
          "base-input",
          {
            staticClass: "mb-0",
            attrs: { label: `Field: ${_vm.label}`, required: "" },
            on: { input: _vm.onInput },
            model: {
              value: _vm.columnName,
              callback: function ($$v) {
                _vm.columnName = $$v
              },
              expression: "columnName",
            },
          },
          [
            _c(
              "small",
              {
                staticClass: "form-text text-muted",
                attrs: { slot: "helperText" },
                slot: "helperText",
              },
              [
                _vm._v(
                  " Enter custom name to relabel the " +
                    _vm._s(_vm.label) +
                    " field "
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ml-3" },
      [
        _c(
          "el-tooltip",
          { attrs: { content: "Remove column", "open-delay": 500 } },
          [
            _c("el-button", {
              staticStyle: { "margin-top": "1.75rem" },
              attrs: {
                icon: "el-icon-close",
                size: "mini",
                type: "danger",
                circle: "",
              },
              on: { click: _vm.onRemove },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }