import { render, staticRenderFns } from "./ResponseTemplates.vue?vue&type=template&id=567f8fea&scoped=true&"
import script from "./ResponseTemplates.vue?vue&type=script&lang=js&"
export * from "./ResponseTemplates.vue?vue&type=script&lang=js&"
import style0 from "./ResponseTemplates.vue?vue&type=style&index=0&id=567f8fea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "567f8fea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/service/packages/reviewbox-web-app/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('567f8fea')) {
      api.createRecord('567f8fea', component.options)
    } else {
      api.reload('567f8fea', component.options)
    }
    module.hot.accept("./ResponseTemplates.vue?vue&type=template&id=567f8fea&scoped=true&", function () {
      api.rerender('567f8fea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Account/ResponseTemplates/ResponseTemplates.vue"
export default component.exports